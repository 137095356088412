import React from 'react';
import { connect } from 'react-redux';
import { Button, Switch, Avatar } from 'antd';
import _ from 'underscore';
import { withRouter } from 'react-router-dom';
import './profile.css';
import { deleteAccount } from '../../endpoints';
import swal from 'sweetalert';
import { setLogout, setUserUpdate } from '../../action/authentication/authentication_actions';
import { store } from '../../index';

const Profile = ({ user, ...props }) => {
    const handleDeleteAccount = () => {
        swal({
            title: 'Attention',
            text: 'Cette action est irréversible, souhaitez-vous vraiment continuer ?',
            icon: 'info',
            closeOnClickOutside: false,
            buttons: {
                cancel: {
                    text: "Annuler",
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: "Confirmer",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                }
            }
        }).then(async confirm => {
            if (confirm) {
                await window.socket.emit('user-disconnect')
                await window.socket.disconnect()
                await props.dispatch(setLogout());
                await deleteAccount({ id: user?.data?.id });
                await props.history.push('/login', { remerciement: true });
            }
        })
    }

    const handleChangeStatus = () => {
        const statusOnline = store.getState().user?.data?.statusOnline === "online" ? "busy" : "online"
        props.dispatch(setUserUpdate({ statusOnline }))
        window.socket.emit('user-change-status', { id: window.socket.id, statusOnline })
    }

    return <div className="profile-container">
        <div className="information">
            <h2>Mon profil</h2>
            <div className="avatar-container">
                <Avatar size="large" src={user?.data?.avatar || ""} style={{ background: 'rgba(' + user?.data?.defaultColor + ')', textTransform: 'uppercase' }} className="avatar-preview">
                    {user?.data?.name?.length > 1 ? user.data.name.substring(0, user.data.name.length - (user.data.name.length - 1)) : user.data.name}
                </Avatar>
            </div>
            <div className="info-list">
                <div>
                    Email : <span className="untouched">{user?.data?.email}</span>
                </div>
                <div>
                    Pseudo : <span className="untouched">{user?.data?.name}</span>
                </div>
                <div>
                    Inscrit depuis le : <span className="untouched">{user?.data?.registerDate && new Date(user?.data?.registerDate).toLocaleDateString('fr', { hour: '2-digit', minute: '2-digit' })}</span>
                </div>
            </div>
        </div>
        <div className="parametre">
            <h2>Paramètres</h2>
            <small>En ayant le statut "Occupé" vous ne recevrez plus les notifications à l'affichage n'y les notifications sonores.</small>
            <div className="info-list">
                <div className="status">
                    <span>Statut de disponibilité</span>
                    <Switch checkedChildren="En ligne" onChange={handleChangeStatus} unCheckedChildren="Occupé" defaultChecked={store.getState().user?.data?.statusOnline === "online" ? true : false} />
                </div>
                <div className="langue">
                    <span>Langue : Français</span>
                    <Button type="primary">Changer</Button>
                </div>
            </div>
        </div>
        <div className="rgpd">
            <h2>RGPD</h2>
            <div className="delete-profile">
                <span>Vous souhaitez ne plus faire partie de l'application WeWorldChat ?</span>
                <Button type="primary" danger onClick={() => handleDeleteAccount()}>Supprimer mon compte</Button>
            </div>
        </div>
    </div>
};

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch });
export default _.compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Profile);