import React from 'react';
import { connect } from 'react-redux';
import {  Layout, Button } from 'antd';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import './Header.css';
import { setEnterPrivateTchat } from '../../action/tchat/tchat_actions';
import { setLogout } from '../../action/authentication/authentication_actions';
import { LogoutOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Header } = Layout;


const HeaderLayout = ({ user, tchat, app, ...props }) => {
    const { t } = useTranslation();
    const handleLogout = () => {
        props.dispatch(setLogout())
        props.dispatch(setEnterPrivateTchat({ userConversation: undefined }))
        window.socket.disconnect()
        props.history.push('/login')
    }

    return (<>
        <Header className="nav-bar-header">
            <Link to="/global"><img src="https://chatelainxpert.be/img/logo.svg" alt="Logo Chatelainxpert" style={{ width: '60%', position: 'relative' }} /></Link>
            {user?.data?.isLogged && <div className="user-action">
                <Button icon={<LogoutOutlined />} danger type="primary" onClick={() => handleLogout()}>{t('login.logout')}</Button>
            </div>}
        </Header>
    </>)
}

const mapStateToProps = ({ user, tchat, app }) => ({ user, tchat, app })
const mapDispatchToProps = dispatch => ({ dispatch })

export default _.compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(HeaderLayout);