import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import './Tchat.css';
import MessageContent from '../MessageContent';
import { Input, Form, Row, Col, Button, Card, Upload, notification } from 'antd';
import { FileImageOutlined, SendOutlined } from '@ant-design/icons';
import _ from 'underscore'
import Dots from './Components/dots';
import { store } from '../../..';
import { withRouter } from 'react-router-dom';
import Emoji from './Components/Emoji/Emoji';
import imageCompression from 'browser-image-compression';
import { useTranslation } from 'react-i18next';

const Tchat = ({ user, tchat, viewTchat, isMobile, currentInterlocUser, ...props }) => {
    const [sendMessage, setSendMessage] = useState({})
    const [messageTyping, setMessageTyping] = useState(false)
    const [isTyping, setIsTyping] = useState(false)
    const [listen, setListen] = useState(false)
    const [form] = Form.useForm();
    const inputElement = useRef()
    const [openEmoji, setOpenEmoji] = useState(false);
    const [_uploadImage, setUploadImage] = useState(undefined);
    const { t } = useTranslation();

    useEffect(() => {
        inputElement.current.focus()
    }, [props.privateId, currentInterlocUser])


    useEffect(() => {
        if (typeof _uploadImage !== 'undefined') {
            if (_uploadImage === 'upload') {
                notification.info({
                    message: t('app.upload_picture_starting'),
                    description:
                        t('app.upload_picture_pending'),
                    duration: 5
                })
            } else {
                notification.success({
                    message: t('app.upload_picture_success_title'),
                    description:
                        t('app.upload_picture_success'),
                    duration: 5
                })
                setUploadImage(undefined)
            }
        }
        // eslint-disable-next-line
    }, [_uploadImage])

    useEffect(() => {
        !listen && window.socket.on('receive-message', data => {
            setIsTyping(false);
        })

        window.socket.on('receive-message-typing', data => {
            setIsTyping(data)
        })

        setListen(true)
        //eslint-disable-next-line
    }, [user.data.id, tchat])

    const handleTyping = () => {
        if (!messageTyping) {
            const tmpValues = {
                pseudo: user.data.name,
                sender: window.socket.id,
                timestamp: new Date().getTime(),
                destination: store.getState().tchat?.data?.userConversation,
                type: 'string'
            }
            setMessageTyping(true)
            window.socket.emit('message-typing', tmpValues)
        }
    }

    const handleSubmit = (values, imageBase64) => {
        setMessageTyping(false)
        const tmpValues = {
            defaultColor: user.data.defaultColor,
            pseudo: user.data.name,
            sender: user.data.id,
            timestamp: new Date().getTime(),
            message: imageBase64 ? imageBase64 : values?.message,
            destination: props.privateId,
            socketId: props.socketId,
            type: imageBase64 ? 'image' : 'string',
            target_lang: props?.target_lang,
            source_lang: user?.data?.target_lang
        }
        setSendMessage(tmpValues)
        form.resetFields()
        inputElement.current.focus()
        setOpenEmoji(false)
        return window.socket.emit('send-message', tmpValues);
    }

    const addEmojiOnField = emoji => {
        let prev = form.getFieldValue('message') || '';
        form.setFieldsValue({ message: prev += emoji })
    }

    let sizeOfImage = 99999999999999;

    const uploadImage = async data => {
        if (data.type === 'image/png' || data.type === 'image/jpeg' || data.type === 'image/webp' || data.type === 'image/gif') {
            const reader = new FileReader();
            reader.onloadend = () => {
                handleSubmit(null, reader.result)
                setOpenEmoji(false)
                return Upload.LIST_IGNORE;
            }
            await imageCompression(data, { maxSizeMB: 1, useWebWorker: true }).then(res => {
                if ((res.size / 1024).toFixed(0) === sizeOfImage && sizeOfImage > 400) {
                    return notification.error({
                        description: t('app.upload_img_error'),
                        duration: 5
                    })
                }
                if (sizeOfImage >= 400) {
                    setUploadImage('upload')
                    uploadImage(res);
                } else {
                    setUploadImage('done')
                    reader.readAsDataURL(res)
                }
                sizeOfImage = (res.size / 1024).toFixed(0);
            })
        }
        return Upload.LIST_IGNORE;
    }

    return <>
        <div className="container-header-tchat">
            <Card title={
                <div className="flex-content">
                    <div style={{ display: 'flex' }}>
                        <span>
                            {currentInterlocUser}
                        </span>
                        <div style={{ ...(!isTyping ? { visibility: 'hidden' } : { visibility: 'visible' }) }}>
                            <small style={{ display: 'flex' }}>{t('app.writing_peer_msg')} <Dots /></small>
                        </div>
                    </div>
                </div>} id="card-tchat-content" className="card-container-header-tchat">
                <MessageContent sendMessage={sendMessage} usersMatch={`${props.privateId}:${user.data.id}`} viewTchat={e => viewTchat(e)} />
                <div>
                    <Form form={form} name="form" onFinish={handleSubmit}>
                        <Row gutter={4} style={{ display: 'flex', margin: 0 }}>
                            <Emoji onEmojiChoose={({ emoji }) => addEmojiOnField(emoji)} setOpen={setOpenEmoji} open={openEmoji} />
                            <Upload className="upload-image" beforeUpload={uploadImage} accept="image/png, image/jpeg, image/webp">
                                <Button icon={<FileImageOutlined />} title={t('app.send_picture')}></Button>
                            </Upload>
                            <Col className="form-col">
                                <Form.Item name="message" rules={[{ required: true, message: t('app.write_msg_error') }]}>
                                    <Input type="text" ref={inputElement} placeholder={t('app.write_msg_placeholder')} onChange={handleTyping} onClick={() => setOpenEmoji(false)} />
                                </Form.Item>
                            </Col>
                            <Button htmlType="submit" type="primary" icon={<SendOutlined />} />
                        </Row>
                    </Form>
                </div>
            </Card>
        </div>
    </>
}

const mapStateToProps = ({ user, tchat }) => ({ user, tchat });
const mapDispatchToProps = dispatch => ({ dispatch })

export default _.compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Tchat)