import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'underscore';
import { Form, Button, Input, Select } from 'antd';
import { Helmet } from "react-helmet";
import './Login.css'
import { setLogin } from '../../action/authentication/authentication_actions';
import { doLogin } from '../../endpoints';
import { UserOutlined, MailOutlined } from '@ant-design/icons';
import FR_FLAG from './fr.png';
import NL_FLAG from './nl.png';
import Stars from './StarsComponent';
import './StarsComponent/stars.css';
import { useTranslation } from "react-i18next";

const Login = ({ user, tchat, app, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [socket, setSocket] = useState(null)
    const [form] = Form.useForm();
    const StarsAnimation = new Stars();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setSocket(true);
        typeof window.socket.id === 'undefined' && window.socket.connect()
        StarsAnimation.outputStars();
        // eslint-disable-next-line
    }, [])

    const onChangeLanguage = lng => {
        i18n.changeLanguage(lng);
    }

    const onFinish = values => {
        setIsLoading(true);
        doLogin({ name: values.pseudo, email: values.email, target_lang: values.flag, socketId: window.socket.id })
            .then(async (data) => {
                const { error_exception } = data;
                if (error_exception) {
                    setIsLoading(false);
                    form.setFields([
                        {
                            name: 'pseudo',
                            errors: [error_exception]
                        }
                    ])
                }
                if (!error_exception && !user?.data?.id) {
                    await props.dispatch(setLogin({
                        pseudo: data?.pseudo,
                        statusOnline: data?.statusOnline,
                        id: data?._id,
                        socketId: window.socket.id,
                        defaultColor: `${data?.defaultColor?.r},${data?.defaultColor?.g},${data?.defaultColor.b},${data?.defaultColor?.a}`,
                        email: data?.email,
                        target_lang: values.flag.toUpperCase(),
                        registerDate: data?.registerDate
                    }));
                    await window.socket.emit('users', {
                        pseudo: data?.pseudo,
                        statusOnline: data?.statusOnline,
                        id: data?._id,
                        target_lang: values.flag.toUpperCase(),
                        socketId: window.socket.id,
                    });
                    setIsLoading(false);
                    await props.history.push('/global')
                }
            })
            .catch(err => { setIsLoading(false) })
    }

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{t('login.title')}</title>
        </Helmet>
        <div className="layout-login">
            <div id="stars"></div>
            <div className="layout-content">
                <div className="picture-form">
                    <img src="https://chatelainxpert.be/img/logo.svg" alt="Logo chatelainxpert" />
                </div>
                <small className="title-form">{t('login.login_form.title_form')}</small>
                <Form className="layout-login-form" onFinish={onFinish} form={form} style={{ display: 'flex' }}>
                    <Form.Item name="flag" rules={[{ required: true, message: t('login.login_form.flag_msg') }]} initialValue="fr">
                        <Select defaultValue="fr" onChange={onChangeLanguage}>
                            <Select.Option value="fr"><div><img src={FR_FLAG} width={20} alt="fr" /> Français</div></Select.Option>
                            <Select.Option value="nl"><div><img src={NL_FLAG} width={20} alt="nl" /> Netherlands</div></Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="pseudo" rules={[{ required: true, message: t('login.login_form.name_error') }]}>
                        <Input prefix={<UserOutlined />} type="text" autoFocus placeholder={t('login.login_form.name_placeholder')} allowClear={true} />
                    </Form.Item>
                    <Form.Item name="email" rules={[{ required: true, message: t('login.login_form.email_error') }]} >
                        <Input prefix={<MailOutlined />} type="email" autoFocus placeholder={t('login.login_form.email_placeholder')} allowClear={true} />
                    </Form.Item>
                    <Button htmlType="submit" disabled={!socket} loading={isLoading} type="primary">{t('login.connection')}</Button>
                </Form>
            </div>
        </div>
    </>)
}

const mapStateToProps = ({ user, tchat, app }) => ({ user, tchat, app });
const mapDispatchToProps = dispatch => ({ dispatch })

export default _.compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Login);