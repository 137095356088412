import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import _ from 'underscore';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from "react-helmet";
import Tchat from './Tchat';
import './Home.css'
import { onConnectionRematch } from '../../utils/manager';
import { setEnterPrivateTchat } from '../../action/tchat/tchat_actions';
import { useTranslation } from 'react-i18next';

const Home = ({ user, tchat, ...props }) => {
    useEffect(() => onConnectionRematch(), [])
    const [isMobile, setIsMobile] = useState(window.innerWidth >= 900 ? true : false)
    const [negociation, setNegociation] = useState({ waiting: true, name: '', socketId: '', id: '', target_lang: '' });
    const { t, i18n } = useTranslation();

    window.addEventListener('resize', ev => {
        if (ev?.target?.innerWidth >= 900) {
            return setIsMobile(true)
        }
        return setIsMobile(false)
    })

    useEffect(() => {
        !negociation.waiting && props.history.push(`/conversation/${negociation.id}`, { socketId: negociation.socketId })
    }, [negociation, props.history])

    window.socket.on('negociation', (data) => {
        console.log(data)
        props.dispatch(setEnterPrivateTchat({ ...tchat, userConversation: data.adminSocketId }));
        setNegociation({ waiting: false, name: data.pseudo, socketId: data.adminSocketId, id: data.id, target_lang: data.source_lang, source_lang: i18n.language.toUpperCase() })
    })

    return <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Tchattez - {user.data?.name}</title>
        </Helmet>
        <Layout className="home-layout">
            <div className="flex-container">
                {!negociation.waiting ? <Tchat privateId={props.match.params.id} target_lang={negociation.target_lang} source_lang={user?.data?.target_lang || 'FR'} currentInterlocUser={negociation.name} socketId={props?.history?.location?.state?.socketId || undefined} viewTchat={e => console.log(e)} isMobile={!isMobile} />
                    : <div className="spinner-container">
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                        <span>{t('app.waiting_admin_msg')}</span>
                    </div>}
            </div>
        </Layout>
    </>
}

const mapStateToProps = ({ user, tchat }) => ({ user, tchat })
const mapDispatchToProps = dispatch => ({ dispatch })
export default _.compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Home)