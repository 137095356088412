class Stars {
    numberOfStars = 800;
    twinkleFrequencyMinimum = 2; // seconds
    twinkleFrequencyMaximum = 6; // ''
    timerIsActive = false;
  
  constructUniverse = () => {
    
    const mainWidth = document.getElementById("root").scrollWidth;
    const mainHeight = document.getElementById("root").scrollHeight;
  
    for (let i = 0; i < this.numberOfStars; i++) {
      
      const xAxis = Math.floor(Math.random() * mainWidth);
      const yAxis = Math.floor(Math.random() * mainHeight);
  
      const star = document.createElement("div");
      star.classList.add("star");
      star.style.top = yAxis.toString() + "px";
      star.style.left = xAxis.toString() + "px";
      document.getElementById("stars").appendChild(star);
      
    }
  
    const randomRange = (min, max) => {
      return Math.floor(Math.random() * (max + 1 - min) + min);
    };
  
    var stars = document.getElementsByClassName("star");
  
    for (let i = 0; i < stars.length; i++) {
      var randNum = randomRange(this.twinkleFrequencyMinimum, this.twinkleFrequencyMaximum); // twinkle duration
      stars[i].style.animationDuration = randNum + "s";
      stars[i].style['z-index'] = 1;
    }
  };
  
  outputStars = () => {
    let starCollection = document.getElementsByClassName("star");
    if (starCollection.length === 0) {
      this.constructUniverse();
    } else {
      const removeStars = (starCollection) => {
        starCollection.forEach((el) => el.remove());
      };
      removeStars(document.querySelectorAll(".star"));
      this.constructUniverse();
    }
  };
}

export default Stars;