import { MailOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import { getFriendRequest, getUserInformation, sendFriendRequest } from '../../../endpoints';
import { connect } from 'react-redux';

const UserModalProfile = ({ _id, visible, onClose, user, tchat, ...props }) => {
    const [_user, setUser] = useState({ avatar: null, socketId: null, email: null, pseudo: null, defaultColor: {}, status: null });
    const [isPending, setIsPending] = useState(false);
    const [isFriend, setIsFriend] = useState(false);

    const sendRequest = (disabled) => {
        if (disabled) return;
        sendFriendRequest({
            sender: user?.data?.id,
            receiver: _id,
            senderSocketId: window?.socket?.id,
            receiverSocketId: _user?.socketId
        })
            .then(result => {
                setUser(u => ({ ...u, status: { status: result.status } }))
                setIsPending(true);
                window.socket.emit('request', { _id: result?._id, receiverSocketId: _user?.socketId, sender: user?.data?.id, receiver: _id, type: 'accept-friend' })
            })
    }

    useEffect(() => {
        _id && getUserInformation({ _id })
            .then(async result => {
                setUser({
                    ...result[0]
                })
            })
            .catch(() => setUser({}))
        _id && getFriendRequest({ _id, ownerId: user?.data?.id, findOne: true })
            .then(result => {
                result?.status === 'pending' ? setIsPending(true) : setIsPending(false)
                result?.status === 'accepted' ? setIsFriend(true) : setIsFriend(false)
            })

    }, [_id, tchat, user])

    return (
        <Modal visible={visible} title={false} footer={false} closable onCancel={onClose} centered>
            <div className="container-profile">
                <div className="avatar">
                    <Avatar size="large" src={_user?.avatar || ""} style={{ background: `rgba(${_user?.defaultColor?.r}, ${_user?.defaultColor?.g}, ${_user?.defaultColor?.b}, ${_user?.defaultColor?.a})`, textTransform: 'uppercase' }} className="avatar-preview">
                        {_user?.pseudo?.length > 1 ?
                            _user?.pseudo.substring(0, _user?.pseudo.length - (_user?.pseudo.length - 1)) :
                            _user?.pseudo}
                    </Avatar>
                </div>
                <div className="flex-container">
                    <div className="information">
                        <div>
                            <span><UserOutlined /> {_user?.pseudo}</span>
                        </div>
                        <div>
                            <span><MailOutlined /> {_user?.email}</span>
                        </div>
                    </div>
                    <div className="friend-request">
                        <Tooltip title={isFriend ? 'Vous êtes déjà amis' : !isPending ? 'Demander en ami' : 'Demande d\'ami en attente'}><Button className={`${isFriend ? 'friendly' : ''}`} type='primary' icon={<UserAddOutlined />} danger={isFriend ? false : !isPending ? false : true} onClick={() => sendRequest(isFriend ? true : !isPending ? false : true)} /></Tooltip>
                    </div>
                </div>

            </div>
        </Modal>
    )
}

const mapStateToProp = ({ user, tchat }) => ({ user, tchat })
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProp, mapDispatchToProps)(UserModalProfile);