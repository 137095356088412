import React from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const LoaderMessage = () => {
    return (
        <div className="loader-spinner-message">
            <Loader
                type="TailSpin"
                color="#00BFFF"
                height={100}
                width={100}
            />
            <small>Chargement des messages en cours ...</small>
        </div>
    )
}

export default LoaderMessage;