import { ENTER_GROUP_DISCUSSION, ENTER_PRIVATE_TCHAT, GET_REQUEST_FRIEND, LOAD_FIRST_TIME, OPEN_MENU, QUIT_GROUP_DISCUSSION, SET_NOTIFICATION } from '../action/tchat/tchat_types'

const tchat = (state = { data: { userConversation: undefined, groupeSubscribed: [], count: 0, requestData: [], group_created: 0, notification: { unread: 0, data: [] } } }, action) => {
    switch (action.type) {
        case ENTER_PRIVATE_TCHAT:
            return {
                ...state,
                data: {
                    ...state.data,
                    userConversation: action.payload.userConversation,
                    enableWebcamCall: action.payload.enableWebcamCall,
                    groupeSubscribed: state.data.groupeSubscribed
                }
            }

        case OPEN_MENU:
            return {
                ...state,
                data: { ...state.data, menuOpened: action.payload.menuOpened }
            }

        case LOAD_FIRST_TIME:
            return {
                ...state,
                data: {
                    ...state.data,
                    notification: false,
                    message: false
                }
            }

        case ENTER_GROUP_DISCUSSION:
            return {
                ...state,
                data: {
                    ...state.data,
                    userConversation: undefined,
                    currentGroupDiscussion: action.payload.currentGroupDiscussion,
                    groupeSubscribed: action.payload.groupeSubscribed,
                    group_created: action.payload.group_created
                }
            }

        case QUIT_GROUP_DISCUSSION:
            return {
                ...state,
                data: {
                    ...state.data,
                    userConversation: undefined,
                    groupeSubscribed: action.payload.groupeSubscribed,
                    currentGroupDiscussion: undefined
                }
            }

        case GET_REQUEST_FRIEND:
            return {
                ...state,
                data: {
                    ...state.data,
                    count: action.payload.count,
                    requestData: action.payload.data.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)
                }
            }

        case SET_NOTIFICATION:
            return {
                ...state,
                data: {
                    ...state.data,
                    notification: {
                        unread: action.payload.count,
                        data: action.payload.data.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)
                    }
                }
            }

        default:
            return state
    }
}

export { tchat }