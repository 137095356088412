import axios from 'axios';

export function getCountUsersConnected(data) {
    return axios
        .get(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/countUsersConnected`, data)
        .then(({ data }) => data)
        .catch(err => new Error(err))
}

export function getPrivateTchat(data) {
    return axios
        .post(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/getPrivateTchat`, data)
        .then(({ data }) => data)
        .catch(err => new Error(err))
}

export function getGlobalTchat(data) {
    return axios
        .post(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/getGlobalTchat`, data)
        .then(({ data }) => data)
        .catch(err => new Error(err))
}

export function getListeGroupe(data) {
    return axios
        .get(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/getListeGroupe`, data)
        .then(({ data }) => data)
        .catch(err => new Error(err))
}

export function getGroupeTchat(data) {
    return axios
        .post(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/getGroupTchat`, data)
        .then(({ data }) => data)
        .catch(err => new Error(err))
}

export function getAvailableGroupName(data) {
    return axios
        .post(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/verifyAvailableGroupName`, data)
        .then(({ data }) => data)
        .catch(err => new Error(err))
}

export function getParticipantsFromGroup(data) {
    return axios
        .get(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/group?participants=${data}`)
        .then(({ data }) => data)
        .catch(err => new Error(err))
}

export function getSubscribedGroups(data) {
    return axios
        .post(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/subscribedGroups`, data)
        .then(({ data }) => data)
        .catch(err => new Error(err))
}

export function updateGroupSid(data) {
    return axios
        .post(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/updateSidFromGroupe`, data)
        .then(({ data }) => data)
        .catch(err => new Error(err))
}

export function getUserAvatar(id) {
    return axios
        .get(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/userAvatar/${id}`)
        .then(({ data }) => data)
        .catch(err => new Error(err))
}

export function getUsersInformation(ids) {
    return axios
        .get(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/usersInformation?ids=${ids}`)
        .then(({ data }) => data)
        .catch(err => new Error(err))
}

export function getUserInformation({ _id }) {
    return axios
        .get(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/userInformation?_id=${_id}`)
        .then(({ data }) => data)
        .catch(err => new Error(err))
}

export function sendFriendRequest(data) {
    return axios
        .post(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/sendFriendRequest`, data)
        .then(({ data }) => data)
        .catch(err => new Error(err))
}

export function getFriendRequest({ _id, findOne, ownerId, skipNumber }) {
    return axios
        .get(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/getFriendsRequests?_id=${_id}${findOne ? '&findOne=true' : '&findOne=false'}${ownerId ? `&ownerId=${ownerId}` : ''}${skipNumber ? `&skipNumber=${skipNumber}` : ''}`)
        .then(({ data }) => data)
        .catch(err => new Error(err))
}

export function replyFrienRequest(data) {
    return axios
        .post(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/replyFrienRequest`, data)
        .then(({ data }) => data)
        .catch(err => new Error(err))
}

export function sendNotification(data) {
    return axios
        .post(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/sendNotification`, data)
        .then(({ data }) => data)
        .catch(err => new Error(err))
}

export function getNotification(data) {
    return axios
        .get(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/getNotification?receiver=${data?.receiver}${data?.skipNumber ? `&skipNumber=${data?.skipNumber}` : ''}`, data)
        .then(({ data }) => data)
        .catch(err => new Error(err))
}

export function markAsReadNotification(id) {
    return axios
        .post(`${process.env.REACT_APP_HOSTNAME || process.env.REACT_APP_ENDPOINT}/markAsReadNotification`, { id })
        .then(({ data }) => data)
        .catch(err => new Error(err))
}